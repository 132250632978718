import React from 'react';
import pictoUserBlue from "../assets/img/pictos/picto-user-blue.png";
import Page from "./Page";
import {UIView} from "@uirouter/react";
import {FormattedMessage} from 'react-intl';
import {User, UserInfos} from "../api/types";
import {InjectedUIRouterProps} from "../api/injectors";
import Step0 from "../components/Certification/Step0";
import Step1 from "../components/Certification/Step1";
import Step2 from "../components/Certification/Step2";
import Step4 from "../components/Certification/Step4";
import Helmet from "../components/Helmet";

type Props = {
    user: UserInfos,
} & InjectedUIRouterProps;

type State = {
    user: User,
    step: number,
}

class Certification extends Page<Props, State> {
    readonly state: State = {
        user: this.props.user,
        step: this.props.user.certificationDate
            ? 4 : this.props.user.certificationStep1
            ? 2 : this.props.user.certificationStep0
            ? 1 : 0,
    };

    nextStep = (e: React.SyntheticEvent) => {
        this.setState(state => ({
            step: state.step + 1,
        }));
    };

    prevStep = () => {
        this.setState(state => ({
            step: state.step - 1,
        }));
    };

    updateUser = (u: any) => {
        this.setState({user: u});
    };

    render() {
        return (
            <>
                <Helmet title={'Je certifie mon compte | Paanteon'} />
                <div className="page-head simple-head">
                    <h1>
                        <img src={pictoUserBlue} className="picto small" alt="picto user"/>
                        <FormattedMessage id="certification.certifyMyAccount"/><span className="text-finish"></span><UIView/>
                    </h1>
                </div>
                <div className={"section"}>
                    <div className="uk-container">
                        {
                            (() => {
                                if (this.state.step === 0) {
                                    return (
                                        <Step0
                                            user={this.state.user}
                                            nextStep={this.nextStep}
                                        />
                                    );
                                } else if (this.state.step === 1) {
                                    return (
                                        <Step1
                                            prevStep={this.prevStep}
                                            nextStep={this.nextStep}
                                        />
                                    );
                                } else if (this.state.step === 2) {
                                    return <Step2 />;
                                } else {
                                    return <Step4 />;
                                }
                            })()
                        }
                    </div>
                </div>
            </>
        );
    }
}

export default Certification;
