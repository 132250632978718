import React from 'react';
import Page from "./Page";
import PublicHeader from "../components/PublicHeader";
import Helmet from "../components/Helmet";
import Footer from "../components/Footer";

type Props = {}

type State = {}

class PrivacyPolicy extends Page<Props, State> {
    render() {
        return (
            <>
                <PublicHeader className="shadowed-header"/>
                <div className="dark-bg">

                    <div className="uk-container main-content">
                        <Helmet title={'Politique de Confidentialité de la Plateforme Paanteon | Paanteon'} />
                        <h1 className="page-title">
                            Politique de Confidentialité de la Plateforme Paanteon
                        </h1>
                    </div>

                    <div className="section --no-select">
                        <div className="uk-container main-content-s body">

                            <p className="margin-bottom">Love Unlimited respecte la vie privée et assure la protection de vos données en tant qu’Utilisateur ou simple visiteur de la Plateforme Paanteon et s’attache particulièrement au respect des libertés et droits fondamentaux de chacun.</p>
                            <p className="margin-bottom">La présente Politique de confidentialité témoigne des engagements de Love Unlimited, en tant que responsable de traitements, dans le cadre de ses activités pour une utilisation responsable des données à caractère personnel (ci-après nommées « données personnelles » ou « informations personnelles »).</p>
                            <p className="margin-bottom">Une donnée personnelle désigne toute information susceptible de se rapporter à une personne physique identifiée ou identifiable directement ou indirectement.</p>
                            <p className="margin-bottom">Cette politique vous informe sur la manière dont Love Unlimited recueille, utilise et partage vos données.</p>
                            <p className="margin-bottom">La présente Politique de confidentialité précise également les droits dont vous disposez en tant qu’Utilisateur ou visiteur de la Plateforme Paanteon.</p>
                            <p className="margin-bottom-xl">Notez que cette Politique de confidentialité est susceptible d’être modifiée ou complétée à tout moment par Love Unlimited, notamment en vue de se conformer à toute évolution législative, réglementaire, jurisprudentielle ou technologique ou pour prendre en compte tout changement dans l’organisation de Love Unlimited ou dans les offres, produits et services proposés. Dans un tel cas, la date de sa mise à jour sera clairement identifiée en tête de la présente politique. Il convient par conséquent de consulter régulièrement la présente Politique de confidentialité afin de prendre connaissance de ses éventuelles modifications.</p>

                            <h2 className="title-2">I. Définitions</h2>
                            <p className="margin-bottom">« <strong>Compte Personnel</strong> » : désigne l’espace personnel d’un Utilisateur personne physique accessible à partir de ses identifiant et mot de passe.</p>
                            <p className="margin-bottom">« <strong>Compte Professionnel</strong> » : désigne l’espace personnel d’un Utilisateur personne morale, société, organisation ou tout autre entité accessible à partir de ses identifiant et mot de passe.</p>
                            <p className="margin-bottom">« <strong>Plateforme Paanteon</strong> » : désigne le service proposé à tout Utilisateur sur le Site Internet Paanteon lui permettant de créer son panthéon personnel en ligne (« Mon Paanteon ») afin d’y graver son univers d’inspirations positives, puis de se regrouper par affinités électives avec d’autres membres au sein de « tribus », et de partager avec eux autour de préférences et goûts en commun.</p>
                            <p className="margin-bottom">« <strong>Site Internet</strong> » : désigne le site internet Paanteon, disponible à partir du lien URL  <a href="https://www.paanteon.com">www.paanteon.com</a>, permettant aux Utilisateurs d’accéder à la Plateforme Paanteon.</p>
                            <p className="margin-bottom-xl">« <strong>Utilisateur</strong> » : désigne tout utilisateur, personne physique de la Plateforme Paanteon y compris tout simple visiteur de la Plateforme.</p>

                            <h2 className="title-2">II. Coordonnées du responsable de traitements et du délégué à la protection des données</h2>
                            <h3 className="title-3">1. Coordonnées du responsable de traitements</h3>
                            <p className="margin-bottom">Le responsable de traitements est Love Unlimited, fournisseur de la Plateforme Paanteon, dont les coordonnées sont :</p>
                            <p>Love Unlimited</p>
                            <p className="margin-bottom-xl">33 rue de la République - 69002 Lyon France</p>

                            <h3 className="title-3">2. Coordonnées du délégué à la protection des données personnelles</h3>
                            <p className="margin-bottom">Love Unlimited a désigné un délégué à la protection des données personnelles pour la Plateforme Paanteon dont les coordonnées sont :</p>

                            <p>Love Unlimited – Plateforme Paanteon</p>
                            <p>Délégué à la Protection des Données</p>
                            <p className="margin-bottom">33 rue de la République - 69002 Lyon France</p>

                            <p className="margin-bottom-xl">Adresse de courrier électronique : <strong>dpo@paanteon.com</strong></p>

                            <h2 className="title-2">III. Finalité du traitement</h2>
                            <p className="margin-bottom">Toutes les données personnelles sont collectées dans un objectif précis (aussi appelé « finalité ») tel que détaillé ci-après.</p>
                            <p className="margin-bottom">Love Unlimited utilise les données personnelles dans le cadre de la fourniture de sa Plateforme Paanteon :</p>
                            <ol type="i" className="margin-bottom-xl">
                                <li>Pour vous identifier et vous permettre d’accéder au Site Internet ;</li>
                                <li>Vous fournir les services auxquels vous pouvez prétendre en tant qu’Utilisateur de la Plateforme Paanteon ;</li>
                                <li>Vous proposer du contenu intéressant et adapté à votre profil et à vos centres d’intérêts ;</li>
                                <li>Effectuer des analyses et des recherches sur les Utilisateurs et les visiteurs de la Plateforme et améliorer le Site Internet et vous proposer de nouvelles fonctionnalités ;</li>
                                <li>Déterminer vos centres d’intérêts et vous présenter du contenu publicitaire pertinent et approprié ;</li>
                                <li>Identifier votre localisation et personnaliser le contenu que nous vous présentons en fonction de votre région ;</li>
                                <li>Assurer la protection de vos données personnelles (par exemple en supprimant vos données personnelles sur demande et/ou l’issue du délai légal de détention des données) ;</li>
                                <li>Résoudre tout litige que vous pourriez avoir avec Love Unlimited concernant l’utilisation de la Plateforme Paanteon ;</li>
                                <li>Assurer la sécurité sur la Plateforme Paanteon.</li>
                            </ol>

                            <h2 className="title-2">IV. Les catégories de données personnelles traitées</h2>

                            <p className="margin-bottom text-underline">1) Les données que vous transmettez directement à Love Unlimited</p>
                            <ul>
                                <li>
                                    <p className="margin-bottom margin-left"><em>Les données nécessaires à la création d’un Compte personnel</em></p>
                                    <p className="margin-bottom">Lorsque vous créez votre Compte Personnel pour accéder à la Plateforme Paanteon vous nous communiquez volontairement certaines informations vous concernant notamment vos nom, prénom, civilité, adresse électronique, ville, code postal, pays, date de naissance, numéro de téléphone. </p>
                                </li>
                                <li>
                                    <p className="margin-bottom margin-left"><em>Vérification d’identité : Certification du compte</em></p>
                                    <p className="margin-bottom">Afin de s’assurer de l’authenticité des profils des Utilisateurs de la Plateforme Paanteon, Love Unlimited peut collecter auprès de vous un justificatif d’identité et/ou un document d’identité complémentaire.</p>
                                    <p className="margin-bottom">Love Unlimited réalise les vérifications nécessaires pour la délivrance du certificat d’authenticité ou le cas échéant la révocation du certificat et authentifier l’Utilisateur lors de la demande de certification.</p>
                                    <p className="margin-bottom">Ces documents ne seront conservés que le temps utile à la certification et pour un délai maximum d’un mois. A l’expiration de ce délai, les documents d’identité fournis seront supprimés.</p>
                                    <p className="margin-bottom">Vous devez accepter le stockage des données personnelles contenues sur le justificatif d’identité pour certifier votre identité et accéder au Service Paanteon.</p>
                                </li>
                                <li>
                                    <p className="margin-bottom margin-left"><em>Les données résultant de votre utilisation de la Plateforme Paanteon</em></p>
                                    <p className="margin-bottom">Lorsque vous utilisez la Plateforme Paanteon vous nous communiquez notamment diverses informations sous la forme de photos, vidéos, sons, textes.</p>
                                    <p className="margin-bottom">Votre page personnelle et l’ensemble de vos publications sur la Plateforme sont publiques par défaut et peuvent être consultées par les autres Utilisateurs. Vous pouvez paramétrer le degré de confidentialité de votre page personnelle dans les paramètres de votre compte. </p>
                                </li>
                            </ul>
                            <p className="margin-bottom text-underline">2) Les informations recueillies indirectement lors de votre utilisation de la Plateforme Paanteon</p>
                            <p className="margin-bottom">Lorsque vous utilisez la Plateforme Paanteon, nos serveurs enregistrent automatiquement des informations, y compris votre adresse IP (qui nous sert à déterminer votre localisation approximative), le type de navigateur que vous utilisez et ses paramètres, la date et l’heure de votre connexion, les données associées à l’utilisation de votre terminal, les données de plantage, par le biais de cookies ou encore la façon dont vous utilisez la Plateforme Paanteon.</p>

                            <p className="margin-bottom text-underline">3) Les données techniques d’utilisation informatique (cookies)</p>
                            <p>Des cookies et traceurs sont déposés et lus lors de la navigation sur la Plateforme Paanteon. Les cookies sont des petits fichiers texte placés sur votre ordinateur ou votre appareil mobile lorsque vous visitez la plupart des sites Internet. Ils sont utilisés pour améliorer votre visite notamment.</p>
                            <p>Lors de la navigation sur le Site Internet, les cookies suivants sont utilisés :</p>
                            <ul>
                                <li><span className="text-underline">Cookies nécessaires</span> : Certains cookies sont nécessaires ou « essentiels » pour que le Site Internet fonctionne comme prévu. Les cookies essentiels sont nécessaires pour que vous puissiez naviguer sur notre Site Internet et utiliser certaines fonctionnalités comme la connexion à votre compte. Ces cookies sont également utilisés pour mémoriser les paramètres de sécurité qui permettent d'accéder à un contenu particulier. Vous n'avez pas la possibilité de refuser les cookies essentiels.</li>
                                <li><span className="text-underline">Cookies de mesure d’audience</span> : les cookies de mesure d’audience permettent à Love Unlimited, pour son propre compte uniquement et de manière anonyme, de comprendre et analyser les performances de son Site Internet, y compris les parties du Site Internet que vous utilisez le plus, les contenus consultés. Ces cookies permettent aussi de tester différentes fonctionnalités du Site et d’améliorer la Plateforme.</li>
                            </ul>
                            <p className="margin-bottom">Conformément à ses engagements Love Unlimited s’engage à ne pas déposer de cookies publicitaires et interdit ses partenaires de faire de même.</p>
                            <p className="margin-bottom-xl">La durée de vie des cookies est limitée à 13 mois et les informations collectées par leur intermédiaire sont conservées pour une durée maximale de 25 mois.</p>

                            <h2 className="title-2">V. Les fondements juridiques des traitements mis en œuvre</h2>
                            <p className="margin-bottom">Love Unlimited utilise les données personnelles des Utilisateurs et visiteurs de son Site Internet uniquement dans les limites autorisées par la loi. Les fondements juridiques de l’utilisation par Love Unlimited des données des Utilisateurs et des visiteurs aux fins énoncées dans la présente Politique de Protection des Données sont les suivants : </p>
                            <ol type="i" className="margin-bottom-xl">
                                <li>Love Unlimited collecte des informations personnelles auprès de vous lorsque cela est nécessaire pour exécuter un contrat conclu avec vous notamment pour créer un compte à votre demande ;</li>
                                <li>L’utilisation de vos informations personnelles est nécessaire aux fins des intérêts légitimes de Love Unlimited, pour vous identifier lorsque vous utilisez la Plateforme Paanteon, vous recommander du contenu, des thèmes, des tribus susceptibles de vous plaire en fonction de votre activité sur la Plateforme Paanteon, vous suggérer d’autres personnes qui partagent les mêmes centres d’intérêts ou encore vous proposer des publicités pertinentes, intéressantes et adaptées à vos centres d’intérêts ou encore vous adresser des communications relatives à la Plateforme Paanteon ;</li>
                                <li>Le respect des obligations légales auxquelles Love Unlimited est soumise.</li>
                            </ol>

                            <h2 className="title-2">VI. Les destinataires de vos données personnelles et transfert</h2>
                            <p className="margin-bottom">Love Unlimited ne procède directement à aucun transfert de données personnelles en dehors de l’Union Européenne.</p>
                            <p className="margin-bottom">Love Unlimited ne communique aucune donnée personnelle à des tiers.</p>
                            <p className="margin-bottom-xl">Love Unlimited peut divulguer vos informations personnelles afin de respecter les termes de ses Conditions générales d’Utilisation ou la présente Politique de Protection des Données ou lorsque nous y sommes autorisés par la loi, notamment en réponse à une demande d’un service répressif ou d’une autorité gouvernementale en relation avec un litige réel ou envisagé, ou pour protéger et défendre nos biens, notre personnel, et d'autres droits ou intérêts.</p>

                            <h2 className="title-2">VII. La durée de conservation de vos données</h2>
                            <p className="margin-bottom">La durée de conservation de vos données dépend des finalités pour lesquelles elles sont traitées. Love Unlimited conserve vos données aussi longtemps que nécessaire pour vous permettre de profiter de la Plateforme Paanteon.</p>
                            <p className="margin-bottom-xl">Si Love Unlimited n’a plus besoin d’utiliser vos informations et n’est plus soumise à une obligation de les conserver pour respecter ses obligations légales ou règlementaires, vos données seront supprimées des systèmes de Love Unlimited.</p>

                            <h2 className="title-2">VIII. La politique de la Plateforme Paanteon relative aux données concernant les mineurs</h2>
                            <p className="margin-bottom-xl">Les mineurs de moins de 15 ans ne sont pas autorisés à utiliser la Plateforme Paanteon. </p>

                            <h2 className="title-2">IX. Vos droits sur vos données</h2>
                            <p className="margin-bottom">Vous disposez de droits sur les données personnelles que vous nous communiquez.</p>
                            <p className="margin-bottom">Love Unlimited communique par la présente Politique de confidentialité des données les informations relatives à ses modalités de collecte et de traitement des données personnelles : finalité du traitement, catégories de données collectées, information relative à la source des données, destinataires, durée de conservation des données personnelles et droits des personnes concernées.</p>
                            <p className="margin-bottom">Le droit d’accès vous permet d’obtenir de Love Unlimited la confirmation du traitement effectué ou non par Love Unlimited s’agissant des données personnelles vous concernant. Le droit d’accès vous permet également, dès lors que vous êtes concernés par le traitement, de demander copie des données personnelles traitées vous concernant. Toutefois, la fourniture de cette copie des données personnelles traitées peut être refusée lorsque cela pourrait révéler des informations personnelles concernant une autre personne ou aurait un impact négatif sur les droits d’une autre personne.</p>
                            <p className="margin-bottom">Le droit de rectification vous permet d’obtenir de Love Unlimited, en tant que responsable de traitements, dans les meilleurs délais, la rectification de vos données personnelles qui sont inexactes ou incomplètes. Compte tenu des finalités du traitement, vous avez le droit d’obtenir que ses données personnelles incomplètes soient complétées, y compris en fournissant une déclaration complémentaire.</p>
                            <p className="margin-bottom">Le droit à l’effacement ou droit à l’oubli vous permet d’obtenir de Love Unlimited, en tant que responsable de traitements l’effacement ou la suppression, dans les meilleurs délais, de vos données à caractère personnel.</p>
                            <p className="margin-bottom">Il ne s’agit toutefois pas d’un droit d’effacement général et il existe des exceptions notamment lorsque Love Unlimited, en tant que responsable de traitements a besoin d’utiliser les informations concernées afin d’assurer sa défense dans le cadre d’une demande d’ordre juridique portée à son encontre ou afin d’être en mesure de respecter une obligation légale.</p>
                            <p className="margin-bottom">Le droit à la limitation vous permet de demander la limitation du traitement, ce qui implique que, dans certains cas, vous pouvez demander à Love Unlimited de suspendre momentanément le traitement de vos données ou, à l'inverse, lui demander de les conserver au-delà du temps nécessaire si Love Unlimited entendait les effacer. Lorsque le traitement est restreint, Love Unlimited peut quand même stocker vos données personnelles mais ne peut pas continuer à les utiliser.</p>
                            <p className="margin-bottom">Vous pouvez vous opposer à ce que Love Unlimited traite vos informations pour des motifs liés à votre situation particulière. Love Unlimited peut continuer à traiter les données personnelles s’il existe des motifs d’intérêt légitime impérieux, aux fins du traitement, qui l’emportent sur vos intérêts, droits et libertés ou si Love Unlimited en a besoin afin d’établir, exercer ou défendre ses droits dans le cadre de poursuites judiciaires.</p>
                            <p className="margin-bottom">Le droit à la portabilité vous permet d’obtenir les données personnelles, que vous avez-vous-même fournies à Love Unlimited en tant que responsable de traitements, pour des besoins personnels. L’exercice de ce droit ne doit pas porter atteinte aux droits et liberté de tiers. Pour toute copie supplémentaire demandée par la personne concernée et/ou lorsque la demande est manifestement infondée ou excessive des frais raisonnables peuvent être appliqués.</p>
                            <p className="margin-bottom-xl">Enfin, vous avez le droit de fournir des instructions sur la gestion (par exemple : conservation, effacement et communication) de vos données personnelles après votre décès. Ces instructions peuvent être modifiées ou révoquées à tout moment.</p>

                            <h2 className="title-2">X. L’exercice de vos droits sur vos données</h2>
                            <p className="margin-bottom">Il est possible, à tout moment, de contacter le Délégué à la Protection des Données de Love Unlimited pour exercer vos droits par voie postale ou par voie électronique aux adresses mentionnées à l’article « II – Coordonnées du responsable de traitements et du délégué à la protection des données ».</p>
                            <p className="margin-bottom">Love Unlimited s’engage à répondre aux demandes d’exercice de droits dans un délai raisonnable qui ne saurait dépasser un mois à compter de la réception de la demande. Au besoin, ce délai peut être prolongé de deux mois, compte tenu de la complexité et du nombre de demandes. En cas de prolongation du délai de réponse, Love Unlimited le notifiera au préalable.</p>
                            <p className="margin-bottom">Love Unlimited pourra demander à toute personne de lui fournir les informations nécessaires afin de confirmer son identité et exercer ses droits. Il s’agit d’une mesure de sécurité additionnelle et appropriée, visant à garantir que les données personnelles ne sont pas divulguées à une personne qui n’a pas le droit de les recevoir.</p>
                            <p className="margin-bottom-xl">Vous n’aurez pas à payer de frais afin d’accéder à ses données personnelles (ou pour exercer tout autre droit). Cependant, des frais pourront être appliqués, de manière raisonnable, pour le cas où votre demande s’avérerait, de manière indéniable, non fondée, répétitive ou excessive. Dans de telles circonstances, Love Unlimited sera fondé à refuser de donner suite à la demande.</p>

                            <h2 className="title-2">XI. La sécurité de vos données</h2>
                            <p className="margin-bottom">Des mesures de sécurité physiques, logiques et organisationnelles appropriées ont été prévues par Love Unlimited pour garantir la confidentialité des données, et notamment éviter tout accès non autorisé.</p>
                            <p className="margin-bottom">Vos données personnelles sont maintenues sur des réseaux sécurisés et accessibles par un nombre limité de collaborateurs et de tiers ayant des droits d’accès spécifiques sur de tels systèmes.</p>
                            <p className="margin-bottom-xl">Love Unlimited veille également à ce que ses sous-traitants présentent des garanties appropriées pour assurer la sécurité et la confidentialité de vos données personnelles.</p>

                            <h2 className="title-2">XII. Divers</h2>
                            <p className="margin-bottom-xl">Si vous pensez que Love Unlimited n’a pas respecté la réglementation relative à la protection des données, vous avez le droit de porter plainte auprès de la Commission Nationale de l’Informatique et des Libertés (CNIL), l’autorité française de surveillance de la protection des données.</p>

                            <h2 className="title-2">XIII. Nous contacter</h2>
                            <p className="margin-bottom-xl">Le meilleur moyen de nous contacter ou d’exercer vos droits décrits ci-dessus consiste à ou à nous écrire à l’adresse suivante : <a href="mailto:dpo@paanteon.com">dpo@paanteon.com</a></p>
                            <p className="margin-bottom-xl">Date de dernière mise à jour : novembre 2024</p>
                        </div>
                    </div>
                </div>
                <Footer />
            </>
        );
    }
}

export default PrivacyPolicy;
