import React from 'react';
import Page from "./Page";
import PublicHeader from "../components/PublicHeader";
import {Link} from "../api/injectors";
import Helmet from "../components/Helmet";
import Footer from "../components/Footer";

type Props = {}

type State = {}

class CommunityCode extends Page<Props, State> {
    render() {
        return (
            <>
                <PublicHeader className="shadowed-header"/>
                <div className="dark-bg">

                    <div className="uk-container main-content">
                        <Helmet title={'Règles de conduite de la communauté | Paanteon'} />
                        <h1 className="page-title">
                            Règles de conduite de la communauté
                        </h1>
                    </div>

                    <div className="section --no-select">
                        <div className="uk-container main-content-s body">
                            <p className="margin-bottom">Notre mission avec Paanteon consiste à vous proposer un espace pour vous permettre de créer votre panthéon personnel en ligne (« Mon Paanteon ») afin d’y graver votre univers d’inspirations positives, puis de vous regrouper par affinités électives avec d’autres membres au sein de « tribus », et de partager avec eux autour de préférences et goûts en commun.</p>
                            <p className="margin-bottom">Nous souhaitons pour cela vous apporter la meilleure expérience possible, tant du point de vue de la qualité des échanges que de la sécurité des interactions.</p>
                            <p className="margin-bottom">En plus de nos <Link to="terms-and-condition-of-use">conditions générales d’utilisation</Link>, nous avons édicté les règles suivantes pour la communauté. C’est l’objectif de cette charte que de présenter les principes et les règles d’interaction sur la Plateforme Paanteon. Ces règles s’appliquent à l’ensemble des contenus créés par les utilisateurs de la Plateforme.</p>
                            <p className="margin-bottom">Afin de préserver l’intégrité de notre communauté, nous nous réservons le droit de supprimer des contenus, de suspendre, voire en fonction de la gravité des faits, de fermer un compte dès lors que son utilisateur a une conduite inappropriée, nuisible et contraire aux règles édictées dans cette charte.</p>
                            <p className="margin-bottom-xl">Ces règles sont susceptibles d’évoluer dans le temps et seront mises à jour au fur et à mesure de l’évolution de la communauté.</p>

                            <h2 className="title-2">1. Interdiction de l’anonymat</h2>
                            <p className="margin-bottom">L’anonymat est proscrit sur Paanteon. Les membres disposeront d’un délai d’un an à compter de la création de leur compte pour se faire certifier.</p>
                            <p className="margin-bottom-xl">Si dans cet intervalle, un utilisateur non certifié contrevenait aux règles de la Plateforme telles qu’édictées ici, le compte ne sera plus accessible et la certification d’identité pourra alors devenir immédiatement exigible.</p>

                            <h2 className="title-2">2. Liberté d’expression, responsabilité et respect des autres</h2>
                            <p className="margin-bottom">Nous croyons en la liberté d’expression dans le respect des lois, des bonnes mœurs et de nos règles éthiques. Les valeurs qui nous animent sont des valeurs de fraternité, de tolérance vis-à-vis des opinions et des goûts de chacun et de respect vis-à-vis des personnes et des êtres vivants.</p>
                            <p className="margin-bottom">La liberté de tous implique la responsabilité de chacun. Si la discussion et l’esprit critique sont toujours possibles, les échanges sur la Plateforme se doivent de rester polis, constructifs, civilisés et courtois.</p>
                            <p className="margin-bottom">Si vous êtes membre de Paanteon, c’est que vous souhaitez parler avant tout de ce que vous aimez, adorer, admirer et respecter. Paanteon n’a pas vocation à être un déversoir de négativité, d’insultes et de haine.</p>
                            <p className="margin-bottom">La liberté, c’est aussi parfois « <em>la liberté de dire aux gens ce qu’ils n’ont pas envie d’entendre</em> », pour reprendre l’expression de George Orwell. Ceci implique que vous pouvez parfois être exposé involontairement à des contenus qui ne correspondent pas à vos propres opinions ou vos goûts.</p>
                            <p className="margin-bottom">Si vous estimez que ces contenus contreviennent aux règles de la Plateforme, vous avez toujours la possibilité de nous les signaler. S’il est jugé que ces contenus sont admissibles, ils pourront continuer à être diffusés.</p>
                            <p className="margin-bottom-xl">Dans ce cas, si vous ne souhaitez pas y être exposé, vous avez toujours la possibilité de paramétrer vos réglages de confidentialité afin de ne pas voir les publications des membres en question.</p>

                            <h2 className="title-2">3. Infractions à la loi</h2>
                            <p className="margin-bottom">L’utilisation de la Plateforme Paanteon implique le respect des lois et règlementations applicables. Tout contenu, activité ou comportement manifestement illicite, proposant, encourageant ou sollicitant une activité illégale est interdit.</p>
                            <p className="margin-bottom-xl">Leurs auteurs pourront faire l’objet de signalements aux autorités judiciaires compétentes.</p>

                            <h2 className="title-2">4. Contenus inadmissibles</h2>
                            <p className="margin-bottom">Seront rejetés les contenus, propos et publications qui contiennent :</p>
                            <ul className="margin-bottom-xl" >
                                <li>des propos obscènes ou vulgaires ;</li>
                                <li>des insultes envers un membre ou une personne extérieure, publique ou privée, physique ou morale, ainsi qu’envers un être vivant ;</li>
                                <li>des propos diffamatoires, mensongers, dégradants, invérifiables et/ou qui portent atteinte à l’honneur et à la dignité d’une personne physique ou morale ;</li>
                                <li>des propos et actions incitant à ou permettant la commission d’un crime, d’un délit ou faisant leur apologie ;</li>
                                <li>des propos incitant au suicide ;</li>
                                <li>des propos xénophobes, racistes, antisémites, homophobes, et/ou stigmatisant une communauté de façon haineuse ou généralisatrice ;</li>
                                <li>des propos, images et autres représentations de nature sexuelle, pédopornographique ou pornographique ;</li>
                                <li>des propos, images ou actions appelant à la cruauté envers les animaux ;</li>
                                <li>des propos faisant office de prosélytisme religieux ;</li>
                                <li>la divulgation dans un but malveillant ou non de coordonnées personnelles d’une personne physique, d’informations confidentielles concernant une personne physique ou morale ;</li>
                                <li>des propos illisibles, incompréhensibles ou rédigés en ASCII ou dans tout autre langue, cryptée ou non, autre que le français ;</li>
                            </ul>

                            <h2 className="title-2">5. Partages</h2>
                            <p className="margin-bottom">La diffusion de liens URL à caractère commercial, auto-promotionnel ou publicitaire est interdite.</p>
                            <p className="margin-bottom-xl">Il est autorisé de partager cependant des liens vers d’autres sites à condition que le contenu soit de nature informationnelle et à des fins de découverte.</p>

                            <p className="margin-bottom">Date de mise à jour : novembre 2024</p>
                        </div>
                    </div>
                </div>

                <Footer />
            </>
        );
    }
}

export default CommunityCode;
