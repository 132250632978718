import React, {Component} from 'react';
import {Link} from "../../api/injectors";
import {FeaturedToSend, Tag, TagCategory, Thumbnail, ThumbnailToSend} from "../../api/types";
import filterHelper from "../../api/filterHelper";
import adminRequests from "../../api/adminRequests";
import Notification from "../../api/notification";
import Modal from "../../components/Modal";
import ConfirmModal from "../../components/ConfirmModal";
import {deleteFromArrayAndReturn} from "../../api/helpers";
import moment from "moment";
import Helmet from "../../components/Helmet";

type ThumbnailWithTags = Thumbnail & {Tags: Tag[], tributeCount: number, createdAt: string};

type Props = {
    thumbnails: {data: ThumbnailWithTags[]}
    tagCategories: {data: TagCategory[]}
}

type State = {
    thumbnails: ThumbnailWithTags[]
    search: string
    featuredToSend: FeaturedToSend | null
    thumbnailToDelete: Thumbnail | null
    newThumbnail: ThumbnailToSend
    mergeThumbnailId: number | null
    mergedThumbnailIds: number[]
    thumbnailTags: ThumbnailWithTags | null
    selectedOrder: string | null
    maxThumbnailsDisplayed: number
    tagFilter: TagFilter,
    stateFilter: StateFilter,
    wikipediaFilter: WikipediaFilter,
    officialWebsiteFilter: OfficialWebsiteFilter,
    openedTagCategoryId: number | null,
}

enum TagFilter {
    All,
    Empty,
    Filled
}

enum StateFilter {
    All,
    Processed,
    Unprocessed
}

enum WikipediaFilter {
    All,
    Empty,
    Filled
}

enum OfficialWebsiteFilter {
    All,
    Empty,
    Filled
}

class Thumbnails extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            thumbnails: props.thumbnails.data,
            search: '',
            featuredToSend: null,
            thumbnailToDelete: null,
            newThumbnail: {
                label: '',
            },
            mergeThumbnailId: null,
            mergedThumbnailIds: [],
            thumbnailTags: null,
            selectedOrder: 'youngest',
            maxThumbnailsDisplayed: 100,
            tagFilter: TagFilter.All,
            stateFilter: StateFilter.All,
            wikipediaFilter: WikipediaFilter.All,
            officialWebsiteFilter: OfficialWebsiteFilter.All,
            openedTagCategoryId: null,
        }
    }

    setOpenedTCId = (tcId: number | null) => {
        if (this.state.openedTagCategoryId === tcId) tcId = null;
        this.setState(prevState => ({
            ...prevState,
            openedTagCategoryId: tcId,
        }));
    }

    updateSearch = (event: React.SyntheticEvent<HTMLInputElement>) => {
        const val = event.currentTarget.value;
        this.setState(prevState => ({
            ...prevState,
            search: val,
        }));
    }

    updateThumbnailLabel = (event: React.ChangeEvent<HTMLInputElement>, thumbnailId: number) => {
        const val = event.currentTarget.value;
        this.setState(prevState => {
            const nState = {...prevState};
            for (let i = 0; i < prevState.thumbnails.length; i++) {
                if (prevState.thumbnails[i].thumbnailId === thumbnailId) {
                    nState.thumbnails = [...nState.thumbnails];
                    nState.thumbnails[i].label = val;
                    break;
                }
            }
            return nState;
        });
    }

    updateThumbnailWikipedia = (event: React.ChangeEvent<HTMLInputElement>, thumbnailId: number) => {
        const val = event.currentTarget.value;
        this.setState(prevState => {
            const nState = {...prevState};
            for (let i = 0; i < prevState.thumbnails.length; i++) {
                if (prevState.thumbnails[i].thumbnailId === thumbnailId) {
                    nState.thumbnails = [...nState.thumbnails];
                    nState.thumbnails[i].wikipedia = val;
                    break;
                }
            }
            return nState;
        });
    }

    updateThumbnailWebsite = (event: React.ChangeEvent<HTMLInputElement>, thumbnailId: number) => {
        const val = event.currentTarget.value;
        this.setState(prevState => {
            const nState = {...prevState};
            for (let i = 0; i < prevState.thumbnails.length; i++) {
                if (prevState.thumbnails[i].thumbnailId === thumbnailId) {
                    nState.thumbnails = [...nState.thumbnails];
                    nState.thumbnails[i].website = val;
                    break;
                }
            }
            return nState;
        });
    }

    updateThumbnailCopyright = (event: React.ChangeEvent<HTMLInputElement>, thumbnailId: number) => {
        const val = event.currentTarget.value;
        this.setState(prevState => {
            const nState = {...prevState};
            for (let i = 0; i < prevState.thumbnails.length; i++) {
                if (prevState.thumbnails[i].thumbnailId === thumbnailId) {
                    nState.thumbnails = [...nState.thumbnails];
                    nState.thumbnails[i].copyright = val;
                    break;
                }
            }
            return nState;
        });
    }

    updateThumbnailDisplay = (thumbnailId: number)=>{
        this.setState(prevState =>{
            const nState = {...prevState};
            for (let i = 0; i < prevState.thumbnails.length; i++) {
                if (prevState.thumbnails[i].thumbnailId === thumbnailId) {
                    nState.thumbnails = [...nState.thumbnails];
                    nState.thumbnails[i].display = !prevState.thumbnails[i].display;
                    break;
                }
            }
            return nState;
        })
    }

    updateThumbnail = (thumbnail: Thumbnail) => {
        adminRequests.updateThumbnail(thumbnail.thumbnailId, thumbnail).then(() => {
            Notification.success("Vignette mise à jour avec succès");
        }, function() {
            Notification.error("Erreur lors de la mise à jour de la vignette");
        });
    }

    openFeaturedModal = (thumbnail: Thumbnail) => {
        this.setState(prevState => ({
            ...prevState,
            featuredToSend: {
                thumbnailId: thumbnail.thumbnailId,
                picture: 'https://paanteon.com/public/featured/',
                desc: '',
            },
        }));
    }

    setFeaturedToNull = () => {
        this.setState(prevState => ({
            ...prevState,
            featuredToSend: null,
        }));
    }

    createFeatured = () => {
        adminRequests.createFeatured(this.state.featuredToSend as FeaturedToSend).then(() => {
            Notification.success("Mise à la une faite avec succès");
            this.setState(prevState => ({
                ...prevState,
                featuredToSend: null,
            }));
        }, function() {
            Notification.error("Erreur lors de la mise à la une")
        });
    }

    setFeaturedImage = (event: React.ChangeEvent<HTMLInputElement>) => {
        const val = event.currentTarget.value;
        // @ts-ignore
        this.setState(prevState => ({
            ...prevState,
            featuredToSend: {
                ...prevState.featuredToSend,
                picture: val,
            },
        }));
    }

    setFeaturedDesc = (event: React.ChangeEvent<HTMLInputElement>) => {
        const val = event.currentTarget.value;
        // @ts-ignore
        this.setState(prevState => ({
            ...prevState,
            featuredToSend: {
                ...prevState.featuredToSend,
                desc: val,
            },
        }));
    }

    updateNewThumbnailLabel = (event: React.ChangeEvent<HTMLInputElement>) => {
        const val = event.currentTarget.value;
        this.setState(prevState => ({
            ...prevState,
            newThumbnail: {
                ...prevState.newThumbnail,
                label: val,
            },
        }));
    }

    openDeleteModal = (thumbnail?: Thumbnail) => {
        this.setState(prevState => ({
            ...prevState,
            thumbnailToDelete: thumbnail ? thumbnail : null,
        }));
    }

    deleteThumbnail = () => {
        adminRequests.deleteThumbnail((this.state.thumbnailToDelete as Thumbnail).thumbnailId).then(() => {
            this.setState(prevState => ({
                ...prevState,
                thumbnails: deleteFromArrayAndReturn(prevState.thumbnails, prevState.thumbnails.findIndex(t => t.thumbnailId === prevState.thumbnailToDelete?.thumbnailId)),
                thumbnailToDelete: null,
            }));
        }, () => {
            Notification.error("Une erreur est survenue lors de la supression de la vignette");
        });
    }

    createThumbnail = () => {
        adminRequests.createThumbnail(this.state.newThumbnail).then((res: {data: ThumbnailWithTags}) => {
            this.setState(prevState => ({
                ...prevState,
                thumbnails: [{...res.data, Tags: []}, ...prevState.thumbnails],
                newThumbnail: {
                    label: '',
                },
            }));
        }, () => {
            Notification.error("Une erreur est survenue lors de la création de la vignette");
        });
    }

    openMergeModal = (thumbnail?: Thumbnail) => {
        this.setState(prevState => ({
            ...prevState,
            mergeThumbnailId: thumbnail ? thumbnail.thumbnailId : null,
        }));
    }

    setMergedThumbnailId = (event: React.ChangeEvent<HTMLInputElement>) => {
        const val = parseInt(event.currentTarget.value, 10);
        this.setState(prevState => ({
            ...prevState,
            mergedThumbnailIds: [val],
        }));
    }

    mergeThumbnails = () => {
        adminRequests.mergeThumbnails([this.state.mergeThumbnailId as number, ...this.state.mergedThumbnailIds]).then(() => {
            this.setState(prevState => ({
                ...prevState,
                thumbnails: prevState.thumbnails.filter(t => this.state.mergedThumbnailIds.indexOf(t.thumbnailId) === -1),
                mergeThumbnailId: null,
                mergedThumbnailIds: [],
            }));
        }, () => {
            Notification.error("Une erreur est survenue lors de la fusion");
        });
    }

    openTagsModal = (thumbnail?: ThumbnailWithTags) => {
        this.setState(prevState => ({
            ...prevState,
            thumbnailTags: thumbnail ? {
                ...thumbnail,
                Tags: [...thumbnail.Tags],
            } : null,
        }));
    }

    toggleTag = (tag: Tag) => {
        let idx: number | undefined;
        this.setState(prevState => ({
            ...prevState,
            thumbnailTags: {
                ...(prevState.thumbnailTags as ThumbnailWithTags),
                Tags: (idx = (prevState.thumbnailTags as ThumbnailWithTags).Tags.findIndex(t => t.tagId === tag.tagId)) >= 0 ? deleteFromArrayAndReturn((prevState.thumbnailTags as ThumbnailWithTags).Tags, idx) : [...(prevState.thumbnailTags as ThumbnailWithTags).Tags, tag],
            },
        }));
    }

    saveThumbnailTags = () => {
        adminRequests.setThumbnailTags((this.state.thumbnailTags as ThumbnailWithTags).thumbnailId, (this.state.thumbnailTags as ThumbnailWithTags).Tags.map(t => t.tagId)).then(() => {
            this.setState(prevState => {
                const newThumbnails = [...prevState.thumbnails];
                const thumbnailIndex = newThumbnails.findIndex(t => t.thumbnailId === (this.state.thumbnailTags as ThumbnailWithTags).thumbnailId);
                newThumbnails[thumbnailIndex] = (this.state.thumbnailTags as ThumbnailWithTags);

                return {
                    ...prevState,
                    thumbnails: newThumbnails,
                };
            });
            Notification.success("Tags mis à jour avec succès");
        }, () => {
            Notification.error("Erreur lors de la mise à jour des tags")
        })
    }

    setSelectedOrder = (order: string | null) => {
        this.setState(prevState => ({
            ...prevState,
            selectedOrder: order,
        }));
    }

    processThumbnail = (thumbnail: Thumbnail) => {
        const thumbnailId = thumbnail.thumbnailId;

        adminRequests.processThumbnail(thumbnailId).then(() => {
            Notification.success("Vignettes traitée avec succès");

            this.setState((prevState) => {
                const newThumbnails = [...prevState.thumbnails];
                const thumbnailIndex = newThumbnails.findIndex(t => t.thumbnailId === thumbnailId);
                newThumbnails[thumbnailIndex] = {
                    ...prevState.thumbnails[thumbnailIndex],
                    processed: true,
                };


                return {
                    ...prevState,
                    thumbnails: newThumbnails,
                };
            });
        }, () => {
            Notification.error("Erreur lors du traitement de la vignette");
        });
    }

    increaseThumbnails = () => {
        this.setState(prevState => ({
            ...prevState,
            maxThumbnailsDisplayed: prevState.maxThumbnailsDisplayed + 12,
        }));
    }

    setSelectedFilter<Field extends keyof State>(field: Field, filterValue: State[Field]) {
        this.setState(prevState => ({
            ...prevState,
            [field]: filterValue,
        }));
    }

    render() {
        const hasThumbnailToMerge = this.state.mergeThumbnailId !== null && this.state.mergedThumbnailIds.length && this.state.thumbnails.find(t => t.thumbnailId === this.state.mergedThumbnailIds[0]);

        let filteredThumbnails = filterHelper.deepSearch(this.state.thumbnails, this.state.search);

        switch (this.state.tagFilter) {
            case TagFilter.Empty:
                filteredThumbnails = filteredThumbnails.filter(t => !t.Tags || t.Tags.length === 0);
                break;
            case TagFilter.Filled:
                filteredThumbnails = filteredThumbnails.filter(t => t.Tags && t.Tags.length > 0);
                break;
        }

        switch (this.state.stateFilter) {
            case StateFilter.Processed:
                filteredThumbnails = filteredThumbnails.filter(t => !!t.processed);
                break;
            case StateFilter.Unprocessed:
                filteredThumbnails = filteredThumbnails.filter(t => !t.processed);
                break;
        }

        switch (this.state.wikipediaFilter) {
            case WikipediaFilter.Empty:
                filteredThumbnails = filteredThumbnails.filter(t => !t.wikipedia || t.wikipedia.length === 0);
                break;
            case WikipediaFilter.Filled:
                filteredThumbnails = filteredThumbnails.filter(t => t.wikipedia && t.wikipedia.length > 0);
                break;
        }

        switch (this.state.officialWebsiteFilter) {
            case OfficialWebsiteFilter.Empty:
                filteredThumbnails = filteredThumbnails.filter(t => !t.website || t.website.length === 0);
                break;
            case OfficialWebsiteFilter.Filled:
                filteredThumbnails = filteredThumbnails.filter(t => t.website && t.website.length > 0);
                break;
        }

        const filteredThumbnailsCount = filteredThumbnails.length;

        if (this.state.selectedOrder === 'oldest') filteredThumbnails.sort((a, b) => moment(a.createdAt).diff(moment(b.createdAt)));
        if (this.state.selectedOrder === 'youngest') filteredThumbnails.sort((a, b) => moment(b.createdAt).diff(moment(a.createdAt)));
        if (this.state.selectedOrder === 'alphabetic') filteredThumbnails.sort((a, b) => a.label.localeCompare(b.label));
        if (this.state.selectedOrder === 'popular') filteredThumbnails.sort((a, b) => b.tributeCount - a.tributeCount);

        filteredThumbnails = filteredThumbnails.slice(0, this.state.maxThumbnailsDisplayed);

        return (
            <div className="dark-bg">
                <div className="uk-container">
                    <div className="main-content">
                        <div className="uk-container">
                            <Helmet title={`Vignettes (${this.state.thumbnails.length}) | Paanteon`} />
                            <h1 className="page-title">
                                Vignettes ({this.state.thumbnails.length})
                            </h1>

                            <div uk-grid="" className="uk-grid">
                                <div className="uk-width-1-1">
                                    Ajouter une vignette
                                </div>

                                <div>
                                    <label>Nom de la vignette</label>
                                    <input className="input" onChange={this.updateNewThumbnailLabel} value={this.state.newThumbnail.label}/>
                                </div>

                                <div>
                                    <button className="btn btn-1" onClick={this.createThumbnail}>Créer</button>
                                </div>
                            </div>

                            <div>
                                <div className="margin-bottom">
                                    <Link to="^">&lt; Retour</Link>
                                </div>
                                <input type="search" placeholder="Rechercher..." className="input block" onChange={this.updateSearch}/>
                            </div>

                            <div className="uk-width-1-1 margin-top margin-bottom-m">
                                <div className="uk-width-1-1 margin-bottom">Trier par</div>
                                <button
                                    className={"btn light" + (this.state.selectedOrder === 'alphabetic' ? ' btn-1' : ' btn-outlined')}
                                    onClick={() => this.setSelectedOrder('alphabetic')}
                                >Ordre alphabétique</button>
                                <button
                                    className={"btn light" + (this.state.selectedOrder === 'youngest' ? ' btn-1' : ' btn-outlined')}
                                    onClick={() => this.setSelectedOrder('youngest')}
                                >Les plus récentes</button>
                                <button
                                    className={"btn light" + (this.state.selectedOrder === 'oldest' ? ' btn-1' : ' btn-outlined')}
                                    onClick={() => this.setSelectedOrder('oldest')}
                                >Les plus anciennes</button>
                                <button
                                    className={"btn light" + (this.state.selectedOrder === 'popular' ? ' btn-1' : ' btn-outlined')}
                                    onClick={() => this.setSelectedOrder('popular')}
                                >Les plus populaires</button>
                            </div>

                            <div className="uk-width-1-1 margin-top margin-bottom-m">
                                <div className="uk-width-1-1 margin-bottom">Filtrer par</div>
                                <div className={"uk-grid uk-child-width-auto"}>
                                    <div>
                                        <label>Etat</label>
                                        <div>
                                            <select
                                              className={"select big"}
                                              onChange={(e) => this.setSelectedFilter('stateFilter', parseInt(e.target.value, 10))}
                                              value={this.state.stateFilter}
                                            >
                                                <option value={StateFilter.All}>Tous</option>
                                                <option value={StateFilter.Processed}>Traité</option>
                                                <option value={StateFilter.Unprocessed}>Non traité</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div>
                                        <label>Lien wikipédia</label>
                                        <div>
                                            <select
                                              className={"select big"}
                                              onChange={(e) => this.setSelectedFilter('wikipediaFilter', parseInt(e.target.value, 10))}
                                              value={this.state.wikipediaFilter}
                                            >
                                                <option value={WikipediaFilter.All}>Tous</option>
                                                <option value={WikipediaFilter.Empty}>Vide</option>
                                                <option value={WikipediaFilter.Filled}>Rempli</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div>
                                        <label>Lien site officiel</label>
                                        <div>
                                            <select
                                              className={"select big"}
                                              onChange={(e) => this.setSelectedFilter('officialWebsiteFilter', parseInt(e.target.value, 10))}
                                              value={this.state.officialWebsiteFilter}
                                            >
                                                <option value={OfficialWebsiteFilter.All}>Tous</option>
                                                <option value={OfficialWebsiteFilter.Empty}>Vide</option>
                                                <option value={OfficialWebsiteFilter.Filled}>Rempli</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div>
                                        <label>Tag</label>
                                        <div>
                                            <select
                                              className={"select big"}
                                              onChange={(e) => this.setSelectedFilter('tagFilter', parseInt(e.target.value, 10))}
                                              value={this.state.tagFilter}
                                            >
                                                <option value={TagFilter.All}>Tous</option>
                                                <option value={TagFilter.Empty}>Vide</option>
                                                <option value={TagFilter.Filled}>Rempli</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="margin-top">
                                {
                                    filteredThumbnails.map(thumbnail => {
                                        return <div className="uk-width-1-1 margin-bottom" key={thumbnail.thumbnailId}>
                                            <div className="uk-grid" data-uk-grid="">
                                                <div className="uk-width-1-1">
                                                    ID: {thumbnail.thumbnailId} - Gravé: {thumbnail.tributeCount} - <span className={
                                                        thumbnail.processed ? 'text-green' : 'text-red'
                                                    }>
                                                        { thumbnail.processed ? 'traitée' : 'non traitée' }
                                                    </span>
                                                    <br/>
                                                    {thumbnail.Tags.length > 0 &&
                                                    <span>Tags : {thumbnail.Tags.map(tag => tag.label).join(', ')}</span>
                                                    }
                                                </div>&nbsp;
                                                <div className="uk-width-1-1">
                                                    <input className="input" value={thumbnail.label} onChange={(event) => this.updateThumbnailLabel(event, thumbnail.thumbnailId)}/>
                                                    <input className="input" placeholder="Lien wikipedia" value={thumbnail.wikipedia || ''} onChange={(event) => this.updateThumbnailWikipedia(event, thumbnail.thumbnailId)}/>
                                                    <input className="input" placeholder="Lien site officiel" value={thumbnail.website || ''} onChange={(event) => this.updateThumbnailWebsite(event, thumbnail.thumbnailId)}/>
                                                    <input className="input" placeholder="Copyright" value={thumbnail.copyright || ''} onChange={(event) => this.updateThumbnailCopyright(event, thumbnail.thumbnailId)}/>
                                                </div>
                                                <div className="uk-width-1-1 margin-top">
                                                    <button className="btn btn-1" onClick={() => this.updateThumbnail(thumbnail)}>Sauvegarder</button>
                                                    <button className="btn btn-1" onClick={() => this.openFeaturedModal(thumbnail)}>Mettre à la une</button>
                                                    <button className="btn btn-1" onClick={() => this.openDeleteModal(thumbnail)}>Supprimer</button>
                                                    <button className="btn btn-1" onClick={() => this.openMergeModal(thumbnail)}>Fusionner</button>
                                                    <button className="btn btn-1" onClick={() => this.openTagsModal(thumbnail)}>Voir les tags</button>
                                                    <button className={!thumbnail.display ? "btn thumbnail-hidden" : "btn btn-1"} onClick={() => {
                                                        this.updateThumbnailDisplay(thumbnail.thumbnailId)
                                                        this.updateThumbnail(thumbnail)
                                                    }}>{thumbnail.display ? "Masquer" : "Masqué"}</button>
                                                    {
                                                        !thumbnail.processed && <button className="btn btn-1" onClick={() => this.processThumbnail(thumbnail)}>Traitée</button>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    })
                                }

                                {
                                    this.state.thumbnails && this.state.maxThumbnailsDisplayed < filteredThumbnailsCount ?
                                        <div className="h-center margin">
                                            <button className="btn btn-outlined light-blue" onClick={this.increaseThumbnails}>VOIR PLUS DE VIGNETTES</button>
                                        </div>
                                        : null
                                }
                            </div>

                            {
                                this.state.featuredToSend && <Modal closeButton={true} onClose={this.setFeaturedToNull}>
                                    <div className="uk-grid uk-child-width-1-2">
                                        <div>
                                            <label>Description :</label>
                                            <input type="text" className="input block" onChange={this.setFeaturedDesc}/>
                                        </div>

                                        <div>
                                            <label>Image :</label>
                                            <input type="url" className="input block" onChange={this.setFeaturedImage} value={this.state.featuredToSend.picture}/>
                                        </div>
                                    </div>

                                    <div className="h-center">
                                        <button className="btn btn-1" onClick={this.createFeatured}>Créer</button>
                                    </div>
                                </Modal>
                            }
                        </div>
                    </div>
                </div>

                {
                    this.state.thumbnailToDelete !== null && <ConfirmModal onCancel={() => this.openDeleteModal()} onConfirm={this.deleteThumbnail}>
                        Voulez-vous vraiment supprimer la vignette "{this.state.thumbnailToDelete.label}" ?
                    </ConfirmModal>
                }

                {
                    this.state.mergeThumbnailId !== null && <Modal>
                        <div className="pop-up-title centered">
                            Fusionner avec la vignette N°{this.state.mergeThumbnailId}
                        </div>

                        <div className="margin-bottom-m">
                            <p>Attention : pour fusionner une vignette erronée vers la vignette à conserver, il faut d’abord choisir la vignette à conserver, appuyer sur le bouton Fusionner et ensuite rentrer le numéro de la vignette à faire disparaître</p>
                        </div>

                        <div>
                            <input className="input" onChange={this.setMergedThumbnailId}/>

                            <p>
                                {
                                    this.state.mergedThumbnailIds[0] !== undefined && !hasThumbnailToMerge && <>Ce numéro ne correspond à aucune vignette</>
                                }
                            </p>
                        </div>

                        <div className="pop-up-buttons centered">
                            <button className="btn btn-outlined" onClick={() => this.openMergeModal()}>Annuler</button>
                            <button className="btn btn-1" onClick={() => this.mergeThumbnails()} disabled={!hasThumbnailToMerge}>Fusionner</button>
                        </div>
                    </Modal>
                }

                {
                    this.state.thumbnailTags !== null && <Modal closeButton onClose={() => this.openTagsModal()}>
                        <div className="pop-up-title centered">
                            Tags de la vignette {this.state.thumbnailTags.label}<br/>
                            {
                                this.state.thumbnailTags.Tags.length > 0 &&
                                <span className="body-l">{this.state.thumbnailTags.Tags.map(tag => tag.label).join(', ')}</span>
                            }
                        </div>

                        <div>
                            {
                                this.props.tagCategories.data.map(tagCategory => {
                                    return <div key={tagCategory.tagCategoryId}>
                                        <button
                                                onClick={() => this.setOpenedTCId(tagCategory.tagCategoryId)}
                                                className={`collapsible-tags-head ${this.state.openedTagCategoryId === tagCategory.tagCategoryId ? "opened" : ""}`}>
                                        </button>
                                        <span className='semibold'>{tagCategory.label}</span> | {tagCategory.Tags.filter(t => ((this.state.thumbnailTags?.Tags as Tag[]).findIndex(t2 => t2.tagId === t.tagId) >= 0)).length} tag(s)

                                        { this.state.openedTagCategoryId === tagCategory.tagCategoryId && <div style={{margin: '5px 0 5px 0'}}>
                                            {
                                                tagCategory.Tags.map(tag => <div key={tag.tagId}>
                                                    <label style={{paddingLeft: '25px'}}>
                                                        <input type="checkbox" className="checkbox" checked={(this.state.thumbnailTags?.Tags as Tag[]).findIndex(t => t.tagId === tag.tagId) >= 0} onChange={() => this.toggleTag(tag)}/> {tag.label}
                                                    </label>
                                                </div>)
                                            }
                                        </div> }
                                    </div>
                                })
                            }
                        </div>

                        <div className="pop-up-buttons centered">
                            <button className="btn btn-outlined" onClick={() => this.openTagsModal()}>Annuler</button>
                            <button className="btn btn-1" onClick={this.saveThumbnailTags}>Sauvegarder</button>
                        </div>
                    </Modal>
                }
            </div>
        );
    }
}

export default Thumbnails;
