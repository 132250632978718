/* eslint-disable no-console */
import PublicHome from "../pages/PublicHome";
import Register from "../pages/Register";
import {ReactStateDeclaration, UIView} from "@uirouter/react";
import localStorage from "./localStorage"
import ConnectedHome from "../pages/ConnectedHome";
import {Transition, StateParams} from "@uirouter/core";
import request from "./request";
import {Chat, Thumbnail, ThumbnailDetails, Tribute, User} from "./types";
import InspirationFeed from "../pages/InspirationFeed";
import auth from "./authentication";
import paanteon from "./paanteonRequests";
import images from "./images";
import userRequests from "./userRequests";
import Friends from "../pages/Friends";
import Connected from "../pages/Connected";
import PaanteonUser from "../pages/PaanteonUser";
import PaanteonCollectionsView from "../components/PaanteonCollectionsView";
import PaanteonAllView from "../components/PaanteonAllView";
import TributeDetails from "../pages/TributeDetails";
import NewTribute from "../pages/NewTribute";
import EditTribute from "../pages/EditTribute";
import Profile from "../pages/Profile";
import Galleries from "../pages/Galleries";
import ThumbnailTributes from "../pages/ThumbnailTributes";
import ThumbnailRecommendations from "../pages/ThumbnailRecommendations";
import PublicationPost from "../pages/PublicationPost";
import Contact from "../pages/Contact";
import LostPassword from "../pages/LostPassword";
import ResetPassword from "../pages/ResetPassword";
import InMemoriamPage from "../pages/InMemoriam";
import InMemoriamDetails from "../pages/InMemoriamDetails";
import Archives from "../pages/Archives";
import adminRoutes from "./adminRoutes";
import MyPublications from "../pages/MyPublications";
import Messages from "../pages/Messages";
import Following from "../pages/Following";
import Followers from "../pages/Followers";
import Notifications from "../pages/Notifications";
import Parameters from "../pages/Parameters";
import Account from "../pages/parameters/Account";
import Privacy from "../pages/parameters/Privacy";
import NotificationSettings from "../pages/parameters/NotificationSettings";
import {AxiosError} from "axios";
import Help from "../pages/Help";
import TermsAndConditionsOfUse from "../pages/TermsAndConditionsOfUse";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import Manifest from "../pages/Manifest";
import CommunityCode from "../pages/CommunityCode";
import Certification from "../pages/Certification";
import Presentation from "../pages/Presentation";
import TutorialTribute from "../pages/TutorialTribute";
import paanteonRequests from "./paanteonRequests";
import Tutorial from "../pages/Tutorial";
import { hasOneYearAccount } from "./user";
import Tribe from "../pages/Tribes";

// Routes with react navi kept as model
/* const routes = mount({
    '/': route({
        title: 'Home',
        view: <PublicHome/>
    }),
    '/register': compose(withView(<Register/>), withTitle("Register"),mount({
        '/': route({
            view: <></>
        }),
        '/test': route({
            view: <Test/>
        })
    }))
});*/

const routes = [] as ReactStateDeclaration[];

routes.push({
    name: 'home',
    url: '/home/:guid',
    component: PublicHome,
    resolve: {
        homeData: function() {
            return paanteonRequests.getPublicHomeData();
        },
        checkAuth: [Transition, function(trans: Transition) {
            if (localStorage.isSetLocalUser()) {
                trans.abort();
                trans.router.stateService.go('connected.home')
            }
        }],
    },
    params: {
        guid: null,
    },
});

routes.push({
    name: 'register',
    url: '/register',
    component: Register,
});

routes.push({
    name: 'connected.contact',
    url: '/contact',
    component: Contact,
    resolve: {
        user: [function() {
            return auth.getUserById(localStorage.getLocalUser().userId).then(function(result) {
                return result.data;
            }, function(error) {
                console.error(error);
            });
        }],
    },
});


routes.push({
    name: 'contact',
    url: '/contact',
    component: Contact,
    resolve: {
        checkAuth: [Transition, function(trans: Transition) {
            if (localStorage.isSetLocalUser()) {
                trans.abort();
                trans.router.stateService.go('connected.contact');
            }
        }],
    },
});

routes.push({
    name: 'lost-password',
    url: '/lost-password',
    component: LostPassword,
});

routes.push({
    name: 'reset-password',
    url: '/reset-password/:guid',
    component: ResetPassword,
});

routes.push({
    name: 'terms-and-condition-of-use',
    url: '/terms-and-condition-of-use',
    component: TermsAndConditionsOfUse,
});

routes.push({
    name: 'privacy-policy',
    url: '/privacy-policy',
    component: PrivacyPolicy,
});

routes.push({
    name: 'manifest',
    url: '/manifest',
    component: Manifest,
});

routes.push({
    name: 'community-code',
    url: '/community-code',
    component: CommunityCode,
});

routes.push({
    name: 'connected',
    resolve: {
        checkAuth: [Transition, function(trans: Transition) {
            if (!localStorage.isSetLocalUser()) {
                trans.abort();
                trans.router.stateService.go('home')
            } else {
                return auth.checkAuthenticated().then(() => {
                    return true;
                }, (err) => {
                    if (err.status !== -1) {
                        trans.abort();
                        trans.router.stateService.go('home');
                    }
                });
            }
        }],
        mentionUsers: function() {
            return userRequests.getUsers();
        },
    },
    abstract: true,
    component: Connected,
});

routes.push({
    name: 'connected.profile',
    url: '/profile',
    resolve: {
        user: [function() {
            return auth.getUserById(localStorage.getLocalUser().userId);
        }],
        checkTribute: ['user', Transition, function(user: {data:User&{Tributes : Tribute[] }}, trans: Transition) {
            if (hasOneYearAccount(user.data) && !user.data.certificationDate) {
                trans.abort();
                trans.router.stateService.go('connected.certification')
            }
        }],
        userTribes: [function() {
            return userRequests.getUserTribes(localStorage.getLocalUser().userId).then(function(res) {
                return res.data;
            }, function(err) {
                console.log(err);
            });
        }],
    },
    component: Profile,
});

routes.push({
    name: 'connected.home',
    url: '/home?inviteFriends',
    component: ConnectedHome,
    resolve: {
        new: [function() {
            return request.get('/paanteon/new').then(function(result) {
                return result.data;
            }, function() {
                console.error('couldn\'t retrieve new thumbnails paanteon');
            });
        }],
        memoriam: [function() {
            return paanteon.getInMemoriam();
        }],
        user: [function() {
            return auth.getUserById(localStorage.getLocalUser().userId).then(function(result) {
                return result.data;
            }, function(error) {
                console.error(error);
            });
        }],
        checkTribute: ['user', Transition, function(user: User&{Tributes : Tribute[] }, trans: Transition) {
            if (hasOneYearAccount(user) && !user.certificationDate) {
                trans.abort();
                trans.router.stateService.go('connected.certification')
            }
        }],
    },
});

routes.push({
    name: 'connected.inspiration-feed',
    url: '/inspiration-feed',
    component: InspirationFeed,
    resolve: {
        user: [function() {
            return auth.getUserById(localStorage.getLocalUser().userId).then(function(result) {
                return result.data;
            }, function(error) {
                console.error(error);
            });
        }],
        popular: [function() {
            return images.getPopular().then(function(res) {
                return res.data;
            }, function(error) {
                console.log(error);
            });
        }],
        discover: [function() {
            return paanteon.getDiscover().then(res => {
                return res;
            });
        }],
        feed: [function() {
            return paanteon.getInspirationFeed().then(function(res) {
                return res.data;
            }, function(error) {
                console.log(error);
            });
        }],
        new: [function() {
            return request.get('/paanteon/new').then(function(result) {
                return result.data;
            }, function() {
                console.error('couldn\'t retrieve new thumbnails paanteon');
            });
        }],
        checkTribute: ['user', Transition, function(user: User&{Tributes : Tribute[] }, trans: Transition) {
            // if (user.Tributes.length === 0) {
            //     trans.abort();
            //     trans.router.stateService.go('connected.tutorial')
            // }
            if (hasOneYearAccount(user) && !user.certificationDate) {
                trans.abort();
                trans.router.stateService.go('connected.certification')
            }
        }],
    },
});

routes.push({
    name: 'connected.inspiration-feed.my-publications',
    url: '/my-publications',
    component: MyPublications,
})

routes.push({
    name: 'connected.inspiration-feed.my-publications.publication',
    url: '/:publicationId',
    component: PublicationPost,
    resolve: {
        publication: [Transition, function(trans: Transition) {
            return paanteon.getPublication(trans.params().publicationId);
        }],
    },
    params: {
        publicationId: {
            type: 'int',
        },
    },
})

routes.push({
    name: 'connected.inspiration-feed.publication',
    url: '/publication/:publicationId',
    component: PublicationPost,
    resolve: {
        publication: [Transition, function(trans: Transition) {
            return paanteon.getPublication(trans.params().publicationId).then(publication => {
                if (publication.data.userId === localStorage.getLocalUser().userId) {
                    trans.abort();
                    trans.router.stateService.go('connected.inspiration-feed.my-publications.publication', {publicationId: trans.params().publicationId});
                }
                return publication;
            })
        }],
    },
    params: {
        publicationId: {
            type: 'int',
        },
    },
})

routes.push({
    name: 'connected.friends',
    url: '/friends',
    component: Friends,
    resolve: {
        userFriends: [function() {
            return userRequests.getUserFriends(localStorage.getLocalUser().userId).then(function(res) {
                return res.data;
            }, function(err) {
                console.log(err);
            });
        }],
        suggestedFriends: [function() {
            return userRequests.getSuggestedFriends(localStorage.getLocalUser().userId).then(function(res) {
                return res.data;
            }, function(err) {
                console.log(err);
            });
        }],
        user: [function() {
            return auth.getUserById(localStorage.getLocalUser().userId).then(function(result) {
                return result.data;
            }, function(error) {
                console.error(error);
            });
        }],
        checkTribute: ['user', Transition, function(user: User&{Tributes : Tribute[] }, trans: Transition) {
            // if (user.Tributes.length === 0) {
            //     trans.abort();
            //     trans.router.stateService.go('connected.tutorial')
            // }
            if (hasOneYearAccount(user) && !user.certificationDate) {
                trans.abort();
                trans.router.stateService.go('connected.certification')
            }
        }],
    },
});

routes.push({
    name: 'connected.paanteon',
    url: '/paanteon',
    component: UIView,
    redirectTo: transition => ({state: 'connected.paanteon.user', params: {userId: localStorage.getLocalUser().userId, username: localStorage.getLocalUser().username}}),
    resolve: {
        selfUser: [function() {
            return auth.getUserById(localStorage.getLocalUser().userId);
        }],
        checkTribute: ['selfUser', Transition, function(selfUser: {data:User&{Tributes : Tribute[] }}, trans: Transition) {
            // if (selfUser.data.Tributes.length === 0) {
            //     trans.abort();
            //     trans.router.stateService.go('connected.tutorial')
            // }
            if (hasOneYearAccount(selfUser.data) && !selfUser.data.certificationDate) {
                trans.abort();
                trans.router.stateService.go('connected.certification')
            }
        }],
    },
});

routes.push({
    name: 'connected.paanteon.user',
    url: '/:username/:userId',
    component: PaanteonUser,
    resolve: {
        user: [Transition, 'selfUser', function(trans: Transition, selfUser: {data: User }) {
            if (trans.params().userId === selfUser.data.userId) return selfUser.data;
            return auth.getUserById(trans.params().userId).then(function(result) {
                return result.data;
            }, function(error) {
                console.error(error);
            });
        }],
        popular: [function() {
            return images.getPopular().then(function(res) {
                return res.data;
            }, function(error) {
                console.log(error);
            });
        }],
        discover: [function() {
            return paanteon.getDiscover().then(res => {
                return res;
            });
        }],
        paanteon: [Transition, function(trans: Transition) {
            return paanteon.getUserPaanteon(trans.params().userId).then(result => {
                return result.data;
            }, function(err) {
                console.log(err);
            })
        }],
    },
    params: {
        userId: {
            type: "int",
            value: () => localStorage.isSetLocalUser() ? localStorage.getLocalUser().userId : null,
        },
        username: {
            type: "string",
            value: () => localStorage.isSetLocalUser() ? localStorage.getLocalUser().username : null,
        },
    },
    redirectTo: transition => ({state: 'connected.paanteon.user.galleries.condensed'}),
});

routes.push({
    name: 'connected.paanteon.user.galleries',
    url: '/galleries',
    component: Galleries,
    abstract: true,
});

routes.push({
    name: 'connected.paanteon.user.galleries.condensed',
    url: '/condensed',
    component: PaanteonCollectionsView,
});

routes.push({
    name: 'connected.paanteon.user.galleries.collections',
    url: '/collections',
    component: PaanteonCollectionsView,
});

routes.push({
    name: 'connected.paanteon.user.galleries.all',
    url: '/all',
    component: PaanteonAllView,
});

routes.push({
    name: 'connected.paanteon.user.new-tribute',
    url: '/new-tribute?thumbnailId&collectionId&type&name&year',
    component: NewTribute,
    resolve: {
        popular: [function() {
            return images.getPopular().then(function(res) {
                return res.data;
            }, function(error) {
                console.log(error);
            });
        }],
        discover: [function() {
            return paanteon.getDiscover().then(res => {
                return res;
            });
        }],
        galleries: [function() {
            return paanteon.getGalleries();
        }],
        thumbnail: [Transition, function(trans: Transition) {
            return trans.params().thumbnailId ? paanteon.getThumbnail(trans.params().thumbnailId) : null;
        }],
    },
    params: {
        collectionId: {
            type: 'int',
        },
        thumbnailId: {
            type: 'int',
        },
    },
});

routes.push({
    name: 'connected.paanteon.tribute',
    url: '/tribute',
    component: UIView,
});

routes.push({
    name: 'connected.paanteon.tribute.details',
    url: '/:thumbnailUniqueName/:username/:tributeId',
    component: TributeDetails,
    params: {
        tributeId: null,
    },
    resolve: {
        galleries: [function() {
            return paanteon.getGalleries();
        }],
        tribute: [Transition, function(trans: Transition) {
            return paanteon.getTribute(trans.params().tributeId).then(res => {
                return Promise.resolve(res);
            }, err => {
                return Promise.resolve(err);
            });
        }],
        user: ['tribute', function(tributeData: { data: Tribute, isAxiosError?: false } | (AxiosError<{userId: number}> & {data: undefined})) {
            return (tributeData.data && tributeData.data.userId) || (tributeData.isAxiosError && tributeData.response!.data.userId) ? auth.getUserById(tributeData.data ? tributeData.data.userId as number : tributeData.response!.data.userId).then(function(result) {
                return result.data;
            }, function(error) {
                console.error(error);
                return Promise.reject(error);
            }) : Promise.resolve("no user");
        }],
        popular: [function() {
            return images.getPopular().then(function(res) {
                return res.data;
            }, function(error) {
                console.log(error);
            });
        }],
        discover: [function() {
            return paanteon.getDiscover().then(res => {
                return res;
            });
        }],
        userTributes: ['tribute', function(tributeData: { data: Tribute, isAxiosError?: false } | (AxiosError<{userId: number}> & {data: undefined})) {
            return (tributeData.data && tributeData.data.userId) || (tributeData.isAxiosError && tributeData.response!.data.userId) ? userRequests.getUserTributes(tributeData.data ? tributeData.data.userId as number : tributeData.response!.data.userId).then(function(res) {
                return res.data;
            }, function(error) {
                console.log(error);
                return Promise.reject(error);
            }) : Promise.resolve("no user");
        }],
        engraves: [Transition, function(trans: Transition) {
            return paanteon.getTributeEngraves(trans.params().tributeId).then(res => {
                return Promise.resolve(res);
            }, err => {
                return Promise.resolve(err);
            });
        }],
    },
});

routes.push({
    name: 'connected.paanteon.tribute.details.edit',
    url: '/edit',
    component: EditTribute,
});

routes.push({
    name: 'connected.paanteon.thumbnail',
    url: '/thumbnail/:thumbnailUniqueName/:thumbnailId',
    abstract: true,
    params: {
        thumbnailId: null,
    },
    resolve: {
        thumbnail: [Transition, function(trans: Transition) {
            return paanteon.getThumbnail(trans.params().thumbnailId);
        }],
        thumbnailDetails: [Transition, function(trans: Transition) {
            return paanteon.getThumbnailDetails(trans.params().thumbnailId);
        }],
    },
    component: UIView,
});

routes.push({
    name: 'connected.paanteon.thumbnail.tributes',
    url: '/tributes',
    component: ThumbnailTributes,
    resolve: {
        checkOnlyTribute: ['thumbnailDetails', 'thumbnail', Transition, function(thumbnailDetails: { data: ThumbnailDetails }, thumbnail: { data: Thumbnail }, trans: Transition) {
            if (thumbnailDetails.data.tributes.length === 1) {
                trans.abort();
                trans.router.stateService.go('connected.paanteon.tribute.details', {tributeId: thumbnailDetails.data.tributes[0].tributeId, thumbnailUniqueName: thumbnail.data.uniqueName, username: thumbnailDetails.data.tributes[0].User.username});
            }
        }],
    },
});

routes.push({
    name: 'connected.paanteon.thumbnail.recommendations',
    url: '/recommendations',
    component: ThumbnailRecommendations,
});

routes.push({
    name: 'connected.paanteon.collection',
    url: '/collection/:collectionId',
    abstract: true,
    params: {
        collectionId: null,
    },
    component: UIView,
});

routes.push({
    name: 'connected.paanteon.archives',
    url: '/archives',
    component: Archives,
    resolve: {
        user: [function() {
            return auth.getUserById(localStorage.getLocalUser().userId).then(function(result) {
                return result.data;
            }, function(error) {
                console.error(error);
            });
        }],
        archives: [function() {
            return paanteon.getArchives(localStorage.getLocalUser().userId);
        }],
    },
})

routes.push({
    name: 'connected.in-memoriam',
    url: '/in-memoriam',
    component: InMemoriamPage,
    resolve: {
        memoriam: [function() {
            return paanteon.getInMemoriam();
        }],
        user: [function() {
            return auth.getUserById(localStorage.getLocalUser().userId).then(function(result) {
                return result.data;
            }, function(error) {
                console.error(error);
            });
        }],
        checkTribute: ['user', Transition, function(user: User&{Tributes : Tribute[] }, trans: Transition) {
            // if (user.Tributes.length === 0) {
            //     trans.abort();
            //     trans.router.stateService.go('connected.tutorial')
            // }
            if (hasOneYearAccount(user) && !user.certificationDate) {
                trans.abort();
                trans.router.stateService.go('connected.certification')
            }
        }],
    },
});

routes.push({
    name: 'connected.in-memoriam.details',
    url: '/:memoriamUniqueName/:memoriamId',
    component: InMemoriamDetails,
    resolve: {
        inMemo: ['$stateParams', function($stateParams: StateParams) {
            return paanteon.getInMemoriamDetails($stateParams.memoriamId);
        }],
    },
});

routes.push({
    name: 'connected.messages',
    url: '/messages?userId',
    component: Messages,
    resolve: {
        selfUser: [function() {
            return auth.getUserById(localStorage.getLocalUser().userId);
        }],
        chats: [function() {
            return paanteon.getAllConversations();
        }],
        userFriends: [function() {
            return userRequests.getUserFriends(localStorage.getLocalUser().userId);
        }],
        newUserChat: ['$stateParams', 'chats', 'selfUser', function($stateParams: StateParams, chats: {data: Chat[]}, selfUser: {data: User}) {
            if ($stateParams.userId) {
                const newUserChat = chats.data.find(c => c.sendId === $stateParams.userId || c.destId === $stateParams.userId);
                if (newUserChat)
                    return paanteon.getConversation(newUserChat.chatId).then(res => Promise.resolve(res.data));
                return auth.getUserById($stateParams.userId).then(res => Promise.resolve({
                    Dest: res.data,
                    Messages: [],
                    Sender: selfUser.data,
                    chatId: -1,
                    createdAt: new Date().toISOString(),
                    destId: $stateParams.userId as number,
                    sendId: selfUser.data.userId as number,
                    updatedAt: new Date().toISOString(),
                    unread: 0,
                }))
            }
            return null;
        }],
        contactableUsers: [function() {
            return paanteon.getContactableUsers();
        }],
        user: [function() {
            return auth.getUserById(localStorage.getLocalUser().userId).then(function(result) {
                return result.data;
            }, function(error) {
                console.error(error);
            });
        }],
        checkTribute: ['user', Transition, function(user: User&{Tributes : Tribute[] }, trans: Transition) {
            // if (user.Tributes.length === 0) {
            //     trans.abort();
            //     trans.router.stateService.go('connected.tutorial')
            // }
            if (hasOneYearAccount(user) && !user.certificationDate) {
                trans.abort();
                trans.router.stateService.go('connected.certification')
            }
        }],
    },
    params: {
        userId: {
            type: 'int',
        },
    },
})

routes.push({
    name: 'connected.notifications',
    url: '/notifications',
    component: Notifications,
    resolve: {
        selfUser: [function() {
            return auth.getUserById(localStorage.getLocalUser().userId);
        }],
        notifications: [function() {
            return paanteon.getNotifications(true);
        }],
        user: [function() {
            return auth.getUserById(localStorage.getLocalUser().userId).then(function(result) {
                return result.data;
            }, function(error) {
                console.error(error);
            });
        }],
        checkTribute: ['user', Transition, function(user: User&{Tributes : Tribute[] }, trans: Transition) {
            // if (user.Tributes.length === 0) {
            //     trans.abort();
            //     trans.router.stateService.go('connected.tutorial')
            // }
            if (hasOneYearAccount(user) && !user.certificationDate) {
                trans.abort();
                trans.router.stateService.go('connected.certification')
            }
        }],
    },
});

routes.push({
    name: 'connected.follow',
    url: '/follow',
    component: UIView,
    resolve: {
        user: [function() {
            return auth.getUserById(localStorage.getLocalUser().userId).then(function(result) {
                return result.data;
            }, function(error) {
                console.error(error);
            });
        }],
        checkTribute: ['user', Transition, function(user: User&{Tributes : Tribute[] }, trans: Transition) {
            // if (user.Tributes.length === 0) {
            //     trans.abort();
            //     trans.router.stateService.go('connected.tutorial')
            // }
            if (hasOneYearAccount(user) && !user.certificationDate) {
                trans.abort();
                trans.router.stateService.go('connected.certification')
            }
        }],
    },
});

routes.push({
    name: 'connected.follow.followers',
    url: 'ers',
    component: Followers,
    resolve: {
        follows: function() {
            return userRequests.getFollows(localStorage.getLocalUser().userId);
        },
    },
});

routes.push({
    name: 'connected.follow.following',
    url: 'ing',
    component: Following,
    resolve: {
        follows: function() {
            return userRequests.getFollows(localStorage.getLocalUser().userId);
        },
        suggestedFriends: [function() {
            return userRequests.getSuggestedFriends(localStorage.getLocalUser().userId).then(function(res) {
                return res.data;
            }, function(err) {
                console.log(err);
            });
        }],
    },
});

routes.push({
    name: 'connected.parameters',
    url: '/parameters',
    component: Parameters,
    resolve: {
        user: [function() {
            return auth.getUserById(localStorage.getLocalUser().userId).then(function(result) {
                return result.data;
            }, function(error) {
                console.error(error);
            });
        }],
        checkTribute: ['user', Transition, function(user: User&{Tributes : Tribute[] }, trans: Transition) {
            // if (user.Tributes.length === 0) {
            //     trans.abort();
            //     trans.router.stateService.go('connected.tutorial')
            // }
            if (hasOneYearAccount(user) && !user.certificationDate) {
                trans.abort();
                trans.router.stateService.go('connected.certification')
            }
        }],
    },
});

routes.push({
    name: 'connected.parameters.account',
    url: '/account?newMailToken',
    component: Account,
    params: {
        newMailToken: {
            type: 'string',
        },
    },
});

routes.push({
    name: 'connected.parameters.privacy',
    url: '/privacy',
    component: Privacy,
});

routes.push({
    name: 'connected.parameters.notifications',
    url: '/notification',
    component: NotificationSettings,
});

routes.push({
    name: 'connected.help',
    url: '/help',
    component: Help,
    resolve: {
        user: [function() {
            return auth.getUserById(localStorage.getLocalUser().userId).then(function(result) {
                return result.data;
            }, function(error) {
                console.error(error);
            });
        }],
        checkTribute: ['user', Transition, function(user: User&{Tributes : Tribute[] }, trans: Transition) {
            // if (user.Tributes.length === 0) {
            //     trans.abort();
            //     trans.router.stateService.go('connected.tutorial')
            // }
            if (hasOneYearAccount(user) && !user.certificationDate) {
                trans.abort();
                trans.router.stateService.go('connected.certification')
            }
        }],
    },
})

routes.push({
    name: 'connected.certification',
    url: '/certification',
    component: Certification,
    resolve: {
        user: [function() {
            return auth.getUserById(localStorage.getLocalUser().userId).then(function(result) {
                return result.data;
            }, function(error) {
                console.error(error);
            });
        }],
        // checkTribute: ['user', Transition, function(user: User&{Tributes : Tribute[] }, trans: Transition) {
            // if (user.Tributes.length === 0) {
            //     trans.abort();
            //     trans.router.stateService.go('connected.tutorial')
            // }
        // }],
    },
});

routes.push({
    name: 'connected.presentation',
    url: '/presentation',
    component: Presentation,
    resolve: {
        user: [function() {
            return auth.getUserById(localStorage.getLocalUser().userId).then(function(result) {
                return result.data;
            }, function(error) {
                console.error(error);
            });
        }],
    },
});

routes.push({
    name: 'connected.howToTribute',
    url: '/how-to-tribute',
    component: TutorialTribute,
});

// useless
routes.push({
    name: 'presentation',
    url: '/presentation',
    component: Presentation,
    resolve: {
        checkAuth: [Transition, function(trans: Transition) {
            if (localStorage.isSetLocalUser()) {
                trans.abort();
                trans.router.stateService.go('connected.contact');
            }
        }],
    },
});

routes.push({
    name: 'connected.tutorial',
    url: '/tutorial?collectionId',
    component: Tutorial,
    resolve: {
        user: [function() {
            return auth.getUserById(localStorage.getLocalUser().userId).then(function(result) {
                return result.data;
            }, function(error) {
                console.error(error);
            });
        }],
        galleries: [function() {
            return paanteon.getGalleries();
        }],
        checkTribute: ['user', Transition, function(user: User&{Tributes : Tribute[] }, trans: Transition) {
            if (user.Tributes.length > 0) {
                trans.abort();
                trans.router.stateService.go('connected.home')
            }
        }],
        thumbnail: [Transition, function(trans: Transition) {
            return trans.params().thumbnailId ? paanteon.getThumbnail(trans.params().thumbnailId) : null;
        }],
    },
    params: {
        collectionId: null,
        thumbnailId: null,
    },
});

routes.push({
    name: 'connected.tribes',
    url: '/tribes',
    component: Tribe,
    resolve: {
        user: [function() {
            return auth.getUserById(localStorage.getLocalUser().userId).then(function(result) {
                return result.data;
            }, function(error) {
                console.error(error);
            });
        }],
        userTribes: [function() {
            return userRequests.getUserTribes(localStorage.getLocalUser().userId).then(function(res) {
                return res.data;
            }, function(err) {
                console.log(err);
            });
        }],
        popular: [function() {
            return images.getPopular().then(function(res) {
                return res.data;
            }, function(error) {
                console.log(error);
            });
        }],
        // checkTribute: ['user', function(user: User&{Tributes : Tribute[] }, trans: Transition) {
        //     if (user.Tributes.length < 0) {
        //         trans.abort();
        //         trans.router.stateService.go('connected.tutorial')
        //     }
        // }],
    },
})

routes.push(...adminRoutes);

export default routes;
