import * as React from "react";
import {FunctionComponent, useCallback, useEffect, useMemo, useState} from "react";
import {InjectedIntlProps, injectIntl} from "react-intl";
import authentication from "../api/authentication";
import Notification from "../api/notification";
import {InjectedUIRouterProps, injectUIRouter, Link} from '../api/injectors';
import logo from "img/logo-paanteon-dark-bg.svg";
import {AxiosError, AxiosResponse} from "axios";
import Helmet from "../components/Helmet";
import { hasOneYearAccount } from "../api/user";

type Props = {
  homeData?: AxiosResponse<{
    background: string,
    copyright?: string,
  }>
} & { $stateParams: { guid: string } };

const PublicHome: FunctionComponent<Props & InjectedIntlProps & InjectedUIRouterProps> = (props) => {
  const { router } = props;

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const isLoginFormInvalid = useMemo(() => email.length === 0 || password.length === 0, [email, password]);

  useEffect(() => {
    if (props.$stateParams.guid)
    authentication.validateUserRegistration(props.$stateParams.guid)
      .then(
        () => {
          Notification.success(props.intl.formatMessage({id: 'home.notification.accountValidated'}));
        },
      );
  }, [props]);

  const login = useCallback((event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    authentication.login({email, password}).then((resp) => {
      props.router.stateService.go(
        resp.isFirstLogin ? 'connected.profile' : (hasOneYearAccount(resp.user) && !resp.user.certificationDate) ? 'connected.certification' : 'connected.home',
        !resp.isFirstLogin && resp.nbTributes > 0 && resp.friendsCount <= 3 ? {inviteFriends: true} : {}
      );
    }, (err: AxiosError) => {
      if (err.response) {
        if (err.response.status === 404) {
          Notification.error("Ce couple d'identifiants n'est pas valide");
        } else if (err.response.status === 410) {
          Notification.error("Compte suspendu, merci de contacter le support pour plus d'informations");
        } else if (err.response.status === 412) {
          Notification.error("L'adresse mail de ce compte n'a pas été validée");
        }
      }
    });
  }, [email, password, props.router.stateService]);

  return (
    <div className={"public-home-layout"} style={{backgroundImage: `url(${props.homeData?.data.background})`}}>
      <Helmet title={"Paanteon | Le réseau social inspirationnel | Accueil"} />
      <div className={"container"}>
        <div className={"card-square"}>
          <div className={"card-header"}>
            <img className="logo margin-bottom-s" src={logo} alt="logo Paanteon"/>
            <div className="ptn-em">EN CHACUN DE NOUS, IL Y A UN UNIVERS. PARTAGEONS-LE !</div>
          </div>

          <div className={"card-body"}>
            <form className={"margin-bottom"} onSubmit={login}>
              <div className={"margin-bottom"}>
                <label className={"label-strong"}>SE CONNECTER</label>
              </div>

              <div className={"margin-bottom"}>
                <input
                  placeholder={"E-mail"}
                  className={"input block dark margin-bottom"}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />

                <input
                  placeholder={"Mot de passe"}
                  className={"input block dark margin-bottom-s"}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)} type={"password"}
                />

                <div className={"text-right"}>
                  <Link to={"lost-password"} className="btn link-btn lost-password-link">Mot de passe oublié ?</Link>
                </div>
              </div>

              <div className={"text-center margin-bottom-m"}>
                <button type={"submit"} className={"btn btn-1 btn-big"} disabled={isLoginFormInvalid}>JE ME CONNECTE</button>
              </div>
            </form>

            <hr/>

            <h2 className={"title-7 text-center"}>NOUVEAU SUR PAANTEON ?</h2>
            <div className={"text-center"}>
              <Link to={"register"} className={"btn btn-big btn-1 dark margin-bottom"}>JE M'INSCRIS</Link>
            </div>

            <div className={"disclaimer text-center"}>
              En utilisant les services de Paanteon, vous acceptez notre <strong>
              <a href={router.stateService.href("privacy-policy")} className="underline" target={"_blank"}>politique de confidentialité</a></strong>
              {" "}respectueuse de votre vie privée, nos <strong>
              <a href={router.stateService.href("terms-and-condition-of-use")} target={"_blank"}>conditions générales d'utilisation</a>
              </strong> et notre <strong><a href={router.stateService.href("community-code")} target={"_blank"}>charte de la communauté</a></strong>.
            </div>
          </div>

          <div className={"card-footer"}>
            <ul className={"links-list"}>
              <li><a href={router.stateService.href("manifest")} target={"_blank"}>Manifesto</a></li>
              <li><a href={router.stateService.href("contact")} target={"_blank"}>Contact</a></li>
              <li>&copy;Paanteon</li>
            </ul>
          </div>
        </div>
      </div>
      {props.homeData?.data.copyright && <div className="img-copyright">
        { props.homeData.data.copyright }
      </div> }
    </div>
  );
}

export default injectUIRouter(injectIntl(PublicHome));
