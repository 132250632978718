import React from "react";

function Step2() {
    return (
        <div className="uk-container main-content-s">
            <div className="steps">
                <div className="step done">1</div>
                <div className="sep"></div>
                <div className="step current">2</div>
            </div>
            <h2 className="title-3 h-center text-uppercase margin-large-top">
                Certification en cours de traitement !
            </h2>
            <div>
                <div className="margin-bottom-xl">
                    <p className="body text-center margin-bottom">
                        Merci ! nous avons bien reçu vos documents d'identité.
                    </p>
                    <p className="body text-center">
                        Votre certification est en cours. Nous ne vous contacterons qu’en cas de besoin. Une fois votre compte certifié, vous recevrez un message de confirmation.
                    </p>
                </div>
            </div>
        </div>
    );
}

export default Step2;
