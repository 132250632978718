import React from "react";
import {FormattedMessage, InjectedIntlProps, injectIntl} from "react-intl";
import {validateStep0} from "../../api/certificationRequests";
import Notification from "../../api/notification";
import { User } from "../../api/types";
import { hasOneYearAccount } from "../../api/user";

type Props = {
    user: User,
    nextStep(event: React.SyntheticEvent): any;
} & InjectedIntlProps;

interface StateInterface {
    confirmCC: boolean;
}

class Step0 extends React.Component<Props, StateInterface> {
    public form = React.createRef<HTMLFormElement>();

    constructor(props: Props) {
        super(props);
        this.state = {
            confirmCC: false,
        }
    }

    get canSubmit(): boolean {
        return (!!this.form.current && this.form.current.checkValidity())
            && this.state.confirmCC;
    }

    setConfirmCC = (ev: React.FormEvent<HTMLInputElement>) => {
        const value = Boolean(ev.currentTarget.checked);
        this.setState(prevState => ({
            ...prevState,
            confirmCC: value,
        }));
    }

    nextStep = (event: React.SyntheticEvent) => {
        event.preventDefault();
        validateStep0().then(() => {
            this.props.nextStep(event);
        }, () => {
            Notification.error("Erreur lors de la validation des conditions de certification");
        });
    }

    render() {
        return (
            <>
                {hasOneYearAccount(this.props.user) &&
                (
                    <p className={"body-l margin-bottom text-blue"}>
                        <strong>Le délai d'un an à compter de votre inscription pour certifier votre compte est arrivé à son terme.</strong>
                        {" "}Nous procédons à une certification de l'identité de tous nos membres, une procédure simple, sécurisée et rapide qui prend quelques secondes. Aucune donnée recueillie lors de la certification n'est conservée.
                    </p>
                )}
                <div className="uk-container main-content-s">
                    <h2 className="title-3 h-center margin-xl text-uppercase"><FormattedMessage id="certification.helpUs"/></h2>
                    <form name="authenticityForm" onSubmit={this.nextStep} ref={this.form}>
                        <div className="margin-large">
                            <p className="body">Dans le cadre de notre politique visant à bannir l'anonymat et à garantir l'authenticité de Paanteon, nous recommandons à nos membres de certifier leur compte. Cette certification est réalisée généralement dans les 24 heures et se fait en trois étapes :</p>
                            <ol className="body">
                                <li>Envoi d’une photo recto verso de votre carte d'identité ou la double page de votre passeport*;</li>
                                <li>Vérification de vos documents d’identité (nous vous contactons uniquement en cas de besoin);</li>
                                <li>Validation et certification de votre compte.</li>
                            </ol>
                            <p className="body">Une fois ces étapes réalisées, vous recevrez un message dans votre messagerie vous confirmant que votre compte à bien été certifié.</p>
                        </div>
                        <div className="margin-large">
                            <label className="black body-m">
                                <input type="checkbox" name="confirmCC" onInput={this.setConfirmCC} />
                                <FormattedMessage id="certification.readAndApproved"/>
                            </label>
                        </div>
                        <div className="margin-large">
                        <p className="body-s">* Ces données restent strictement confidentielles et sont stockées de façon sécurisée uniquement durant la procédure de certification (un mois maximum).</p>
                            <div className="margin-large right">
                                <button type="submit" disabled={!this.canSubmit}
                                        className="btn btn-1 btn-big btn-uppercase">
                                    Suivant
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </>
        );
    }
}

export default injectIntl(Step0);
