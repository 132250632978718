import React from 'react';
import {FormattedMessage} from 'react-intl';

interface propsInterface {
    className: string;
};

function AnonymityAndCertification(props: propsInterface) {
    return (
        <div className={props.className}>
            <h2 className="title-4 margin no-margin-top h-center text-light-blue"><FormattedMessage
                id="register.anonymityAndCertification.title"/></h2>
            <div className="anonymity-and-certification">
                <p className="margin-bottom">Afin d’offrir un environnement sécurisé à nos membres, de garantir l’authenticité de notre communauté et la bienveillance des échanges, nous interdisons l’anonymat sur Paanteon.</p>
                <p className="margin-bottom">Nous procédons à une certification de l’identité de tous nos membres, une procédure simple, sécurisée et rapide qui prend quelques secondes. Vous disposez d’un délai d’un an à compter de votre inscription pour effectuer cette certification. Aucune donnée recueillie lors de la certification n’est conservée.</p>
                <p className="margin-bottom">Lorsque vous créez votre compte, vous devez renseigner les nom et prénom que vous utilisez au quotidien. Les identités fantaisistes seront rejetées. Vous devez également fournir des informations exactes à propos de vous et créer un seul compte personnel.</p>
                <p className="margin-bottom">Si vous utilisez un pseudonyme, une fausse identité manifeste ou si vous enfreignez nos règles de conduite, la certification d’identité pourra être requise immédiatement. L’absence de certification entraînera la suppression de votre compte.</p>
                <p className="margin-bottom">Pour demander votre certification, rendez-vous sur vos paramètres de compte et sur l’onglet « Informations de compte », puis cliquez sur « Je souhaite certifier mon compte ».</p>
                <p className="margin-bottom">Tout membre ayant vu son compte utilisateur précédemment supprimé ne pourra pas se réinscrire sur la plateforme.</p>
            </div>
        </div>
    );
}

export default AnonymityAndCertification;
